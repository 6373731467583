@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .table {
    margin: -8px;
    border-spacing: sm;
  }

  .rowValue {
    padding: xs;
    background: black;
    color: white;
  }
}