@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .toolButton {
    display: flex;
    aspect-ratio: 1/0.8;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      margin-bottom: sm;
    }
  }
}
