@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .dropdowns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: md;
    margin-top: lg;
  }
}
