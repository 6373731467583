@value a11yYellow, white, blue, scarlet from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusSmall from "@depop/web-ui-kit/theme/default/border.module.css";
@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .toggleButton {
    position: fixed;
    bottom: md;
    right: md;
    cursor: pointer;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: scarlet;
    color: white;
    height: 40px;
    width: 40px;
    border-radius: borderRadiusSmall;
    z-index: 999;

    &:focus {
      box-shadow: 0 0 0 4px a11yYellow;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
  }

  .modal {
    position: fixed;
    bottom: 80px;
    right: 0;
    left: 0;
    background: white;
    padding: 24px;
    z-index: 999;
    border-radius: borderRadiusSmall;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;

    @media breakpointSmUp {
      right: lg;
      left: unset;
      max-height: 75vh;
      min-width: 400px;
      max-width: calc(100vw - 48px);
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: md;
    margin-bottom: md;
  }

  .actionIconWrapper {
    margin-bottom: md;
    display: flex;
    justify-content: center;
  }

  .backButton {
    display: flex;
    border: none;
    background: none;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0 4px a11yYellow;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
  }

  .moreToolsButton {
    margin-bottom: md;
  }
}
